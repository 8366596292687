<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand :to="{ name: 'Dashboard'}">
        <img src="@/assets/dts.png" height="35px;" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'Dashboard'}">Scoreboard</b-nav-item>
          <b-nav-item :to="{ name: 'Challenges'}">Challenges</b-nav-item>
          <b-nav-item :to="{ name: 'Rules'}">Rules</b-nav-item>
          
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown v-if="user.isSuperuser" right>
            <template slot="button-content">
              <em>Admin</em>
            </template>
            <b-dropdown-item :to="{ name: 'CreateChallenge'}">Create Challenge</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'SubmitResults'}">Submit Results</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right>
            <template slot="button-content">
              <em>More</em>
            </template>
            <b-dropdown-item :to="{ name: 'JackTrice'}">Jack Trice View</b-dropdown-item>
            <b-dropdown-item v-if=user.profile :to="{ name: 'Profile', params: { id: user.profile.id } }">Profile</b-dropdown-item>
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- <b-nav-item @click="logout">Sign Out</b-nav-item> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div style="padding: 15px">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";

export default {
  name: "DefaultContainer",
  data() {
    return {
      user: { name: null, profile: false, isSuperuser: false }
    };
  },
  beforeMount() {
    let self = this;
    api("query { me { id profile { id } isSuperuser username firstName } }").then(data => {
      self.user = data.me;
    });
  },
  methods: {
    logout() {
      let self = this;
      api("mutation { logout{ status } }").then(data => {
        self.$router.push({ name: "Login" });
      });
    }
  }
};
</script>